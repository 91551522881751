import { LaunchDarklyFeatureFlags } from "~/integrations/launch-darkly/LaunchDarklyContext";

export function parseFeatureFlags(flags: Partial<LaunchDarklyFeatureFlags>) {
  return {
    chat: {
      image: {
        upload: flags.rolloutChatImageUpload ?? false,
      },
    },
    tasks: {
      personas: flags.rolloutTasksPersonas ?? false,
      chatBubbles: flags.rolloutTaskPersonasChatBubble ?? false,
    },
    maintenance: flags.operationalMaintenance ?? false,
    matrix: flags.easterEggMatrix ?? false,
    theme: {
      notification: flags.rolloutThemeNotification ?? false,
    },
    notifications: {
      chromeExtension: flags.rolloutChromeExtensionNotification ?? false,
    },
  };
}
